const getGroupedByPaymentMethod = (array, previous, restar) => {
  const resultado = array?.reduce((result, item) => {
    const { name, _id } = item;
    if (!result[name]) {
      let total = 0;
      // if (restar) {
      //   total = item.total * -1;
      // } else {
      // }
      total = item.total;

      result[name] = {
        total: total || 0,
        _id,
        name,
      };
    } else {
      // if (restar) {
      //   let total = result[name].total - item.total;

      //   result[name].total = total;
      // } else {
      // }

      let total = result[name].total + item.total;

      result[name].total = total;
    }

    return result;
  }, previous);

  return resultado || [];
};

const processPaymentArray = (
  array,
  paymentMethodsMap,
  transferKey = "payment",
  amountKey = "total",
  isNegative = false
) => {
  const processedArray = [];
  if (array?.length > 0) {
    array.forEach((item) => {
      const paymentItems = transferKey ? item?.[transferKey] : [item];

      paymentItems?.forEach((payment) => {
        let total = payment[amountKey] || 0;

        // Check for negative operations if relevant
        if (isNegative || item.operation?.toUpperCase() === "EGRESO") {
          total = total * -1;
        }

        processedArray.push({
          ...payment,
          _id: payment?.paymentMethodId,
          name: paymentMethodsMap?.[payment?.paymentMethodId] || "",
          total: total,
        });
      });
    });
  }
  return processedArray;
};

export const arqueoDeCajaData = (data, config) => {
  try {
    if (data?.getAccountBoxPayments && config?.paymentMethods) {
      //! descontar y sumar otras transferencias
      const { moves, transfersFrom, transfersTo } = data.getAccountBoxPayments;

      const paymentMethodsMap = config?.paymentMethods?.reduce(
        (acc, method) => {
          acc[method._id] = method.name;
          return acc;
        },
        {}
      );

      const arrayMoves = processPaymentArray(moves, paymentMethodsMap);

      const to = processPaymentArray(
        transfersTo,
        paymentMethodsMap,
        "transferInput",
        "amount"
      );

      const from = processPaymentArray(
        transfersFrom,
        paymentMethodsMap,
        "transferInput",
        "amount",
        true
      );

      let metodos = {};
      // Moves
      metodos = getGroupedByPaymentMethod(arrayMoves, metodos, false);

      // To
      metodos = getGroupedByPaymentMethod(to, metodos, false);

      // From
      metodos = getGroupedByPaymentMethod(from, metodos, true);

      const definitivo = Object.values(metodos);

      return definitivo || [];
    }

    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getTotalMetodosDePagos = (data, config) => {
  try {
    if (data && config?.paymentMethods) {
      const { moves, corrections, transfersIn, transfersOut, openingData } =
        data;

      const paymentMethodsMap = config?.paymentMethods?.reduce(
        (acc, method) => {
          acc[method._id] = method.name;
          return acc;
        },
        {}
      );

      // Process different transaction types
      const arrayMoves = processPaymentArray(moves, paymentMethodsMap);

      // const correctionsArray = processPaymentArray(
      //   corrections,
      //   paymentMethodsMap
      // );

      // console.log("corrections", corrections);

      // console.log("correctionsArray", correctionsArray);

      const ins = processPaymentArray(
        transfersIn,
        paymentMethodsMap,
        "transferInput",
        "amount"
      );
      const outs = processPaymentArray(
        transfersOut,
        paymentMethodsMap,
        "transferInput",
        "amount",
        true
      );
      const openings = processPaymentArray(
        openingData,
        paymentMethodsMap,
        null,
        "amount"
      );

      let metodos = {};
      metodos = getGroupedByPaymentMethod(arrayMoves, metodos, false);

      // Revisar en que casos es necesario computar esto.
      // metodos = getGroupedByPaymentMethod(correctionsArray, metodos, false);
      metodos = getGroupedByPaymentMethod(ins, metodos, false);
      metodos = getGroupedByPaymentMethod(outs, metodos, true);
      metodos = getGroupedByPaymentMethod(openings, metodos, false);

      const definitivo = Object.values(metodos) || [];

      return definitivo;
    }

    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
