import { useLazyQuery } from "@apollo/client";
import { Button, Drawer, Table } from "antd";
import { GET_MOVES_DETAIL } from "../../../../../graphql/query/Cajas";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { PDFViewer } from "@react-pdf/renderer";
import { Recibo } from "../../../reports/components/recibo/Recibo";
import { columnsMovimientosDetalleCierre } from "./utils";
import { ArqueoDeCaja } from "../../../reports/components/cierreCaja/ArqueoDeCaja";
import { getTotalMetodosDePagos } from "../../../../../utils/getTotalMetodosDePagos";
import { CierreCajaModal } from "../../../finanzas/cajas/cierreCajaModal/CierreCajaModal";
import { SECCION_PERMISOS } from "../../../../../assets/permisos-template";
import { isAvailable } from "../../../../../utils/isAvailable";
import { ModalTransferencias } from "../../../transferencias/ModalTransferencias";

export const DetalleCierre = ({
  selectedCierre,
  setSelectedCierre,
  setCajaData,
  cajaData,
}) => {
  const { alumnos, messageApi, config, user } = useContext(GlobalContext);

  const [getMovesDetailResolver, { data, loading }] = useLazyQuery(
    GET_MOVES_DETAIL,
    {
      fetchPolicy: "network-only",
    }
  );

  const [showDrawer, setShowDrawer] = useState(false);

  const [showArqueoDeCaja, setShowArqueoDeCaja] = useState(false);

  const [pdfData, setPdfData] = useState({
    open: false,
    data: null,
  });

  const [showNewTransfer, setShowNewTransfer] = useState({
    open: false,
    type: null,
    item: null,
  });

  const [showTransferDetail, setShowTransferDetail] = useState();

  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    if (selectedCierre?.accountBoxId) {
      getMovesDetailResolver({
        variables: {
          accountBoxId: selectedCierre.accountBoxId,
          abHistoryId: selectedCierre._id,
        },
      });
    }
  }, [selectedCierre]);

  useEffect(() => {
    if (data?.getMovesDetailResolver) {
      const {
        moves,
        corrections,
        transfersIn,
        transfersOut,
        openingData,
        openingTotal,
      } = data?.getMovesDetailResolver;

      let mergedMoves = [];

      if (moves?.length > 0) {
        //date
        mergedMoves = moves.map((element) => {
          return { ...element, type: "move" };
        });
      }

      // if (corrections?.length > 0) {
      //   // date
      //   mergedMoves.push(
      //     ...corrections.map((element) => {
      //       console.log("element", element);
      //       return { ...element, type: "correction" };
      //     })
      //   );
      // }

      mergedMoves.sort((a, b) => b.date - a.date);

      let transferencias = [];

      // setear conceptos

      if (transfersIn?.length > 0) {
        const sorted = transfersIn
          ?.map((element) => {
            return { ...element, date: element.createdAt, type: "transferIn" };
          })
          ?.sort((a, b) => b.date - a.date);
        //createdAt
        transferencias.unshift(...sorted);
      }

      if (transfersOut?.length > 0) {
        const sorted = transfersOut
          ?.map((element) => {
            return { ...element, date: element.createdAt, type: "transferOut" };
          })
          ?.sort((a, b) => b.date - a.date);

        //createdAt
        transferencias.unshift(...sorted);
      }

      transferencias.sort((a, b) => b.date - a.date);

      if (openingData) {
        mergedMoves.unshift({
          ...openingData,
          date: openingData.dateFrom,
          type: "openingData",
          total: openingTotal || 0,
          transferInput: openingData,
          _id: "openingData",
        });
      }

      setDataTable([...transferencias, ...mergedMoves]);
    } else {
      setDataTable([]);
    }
  }, [data]);

  const conceptos = useMemo(() => {
    return getTotalMetodosDePagos(data?.getMovesDetailResolver, config);
  }, [data, config]);

  return (
    <div>
      <Table
        style={{
          marginTop: 20,
          width: "clamp(400px, 90vw, 1000px)",
          overflow: "auto",
        }}
        loading={loading}
        rowKey={(item) => {
          return `${item.type}-${item._id}`;
        }}
        columns={columnsMovimientosDetalleCierre(
          cajaData,
          setPdfData,
          setShowDrawer,
          alumnos,
          setSelectedCierre,
          setShowTransferDetail
        )}
        dataSource={dataTable || []}
        pagination={{
          pageSize: 8,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} movimientos`,
          showSizeChanger: false,
        }}
      />
      <span className="horizontal" style={{ marginTop: "12px" }}>
        {selectedCierre?.status?.toUpperCase() === "OPEN" &&
          isAvailable(
            "settings",
            SECCION_PERMISOS.crear,
            user?.rol?.permissions
          ) && (
            <Button
              className="btn-guardar"
              onClick={() => {
                setShowNewTransfer({ open: true, type: "new", item: null });
              }}
            >
              Cerrar caja
            </Button>
          )}

        <Button
          className="btn-guardar"
          onClick={() => setShowArqueoDeCaja(true)}
          disabled={loading}
        >
          Total métodos de pago
        </Button>
        <Button
          onClick={() => setSelectedCierre(null)}
          style={{ marginLeft: "auto" }}
          className="btn-cancelar"
        >
          Volver
        </Button>
      </span>
      <Drawer
        open={showArqueoDeCaja}
        width={"calc(100% - 70px)"}
        destroyOnClose
        onClose={(v) => setShowArqueoDeCaja(false)}
      >
        <PDFViewer style={{ width: "100%", height: "100%", border: "none" }}>
          <ArqueoDeCaja
            dataCierre={{ name: cajaData?.item?.name, context: "caja" }}
            dataConceptos={conceptos}
          />
        </PDFViewer>
      </Drawer>
      <Drawer
        open={pdfData.open}
        width={"calc(100% - 70px)"}
        destroyOnClose
        onClose={(v) => setPdfData({ open: false, data: null })}
      >
        <PDFViewer style={{ width: "100%", height: "100%", border: "none" }}>
          <Recibo data={pdfData.data} alumnos={alumnos} />
        </PDFViewer>
      </Drawer>

      <CierreCajaModal
        showNewTransfer={showNewTransfer}
        setShowNewTransfer={setShowNewTransfer}
        conceptos={conceptos}
        selectedCierre={selectedCierre}
        setSelectedCierre={setSelectedCierre}
      />

      {showTransferDetail?._id && (
        <ModalTransferencias
          dataDetalle={showTransferDetail}
          setDataDetalle={setShowTransferDetail}
        />
      )}
    </div>
  );
};
