import { Button, Checkbox, Input, Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { GlobalContext } from "../../../../context/GlobalContext";
import { NEW_TRANSFER } from "../../../../../graphql/mutation/Transferencias";
import { thousandFormatter } from "../../../../../utils/thousandFormatter";
import { CLOSE_ACCOUNT_BOX } from "../../../../../graphql/mutation/Cajas";
import { TotalTransferencia } from "../../../transferencias/TotalTransferencia";

export const TransferenciaCierreCaja = ({
  conceptos,
  showNewTransfer,
  setShowNewTransfer,
  selectedCierre,
  setSelectedCierre,
}) => {
  const { config, messageApi } = useContext(GlobalContext);

  const [
    newTransferResolver,
    { data: dataNew, loading: loadingNew, error: errorNew },
  ] = useMutation(NEW_TRANSFER, {
    fetchPolicy: "network-only",
  });

  const [
    newABCloseResolver,
    { data: dataCierre, loading: loadingCierre, error: errorCierre },
  ] = useMutation(CLOSE_ACCOUNT_BOX, { fetchPolicy: "network-only" });

  const [selectedABFrom, setSelectedABFrom] = useState(null);
  const [selectedABTo, setSelectedABTo] = useState(null);

  const [incluyeTransferencia, setIncluyeTransferencia] = useState(true);

  const [resultados, setResultados] = useState([]);

  const abOptions = useMemo(() => {
    if (config?.accountingBoxes && Array.isArray(config?.accountingBoxes)) {
      let array = config.accountingBoxes.map((caja) => {
        if (caja._id === selectedCierre?.accountBoxId) {
          setSelectedABFrom(caja?._id);
        }

        if (caja.isTesoreria) {
          setSelectedABTo(caja?._id);
        }

        return {
          value: caja._id,
          label: caja.name,
          isTesoreria: caja.isTesoreria,
        };
      });

      return array;
    }

    return [];
  }, [config]);

  const handleGuardarTransferencia = () => {
    if (!selectedABFrom || !selectedABTo) {
      messageApi.info("Necesita seleccionar caja de origen y destino");
      return;
    }

    if (selectedABFrom === selectedABTo) {
      messageApi.info("Cajas de destino y de origen no pueden ser iguales");
      return;
    }

    if (resultados?.length < 1) {
      messageApi.info("No hay conceptos que transferir");
      return;
    }

    if (
      resultados.some((concepto) => {
        if (concepto.amount == null) {
          messageApi.info(`${concepto?.name} necesita un valor`);
          return true;
        }

        if (isNaN(concepto.amount)) {
          messageApi.info(`${concepto?.name} no tiene un número`);
          return true;
        }

        if (concepto.amount < 0) {
          messageApi.info(
            `${concepto?.name} no puede contener números negativos`
          );
          return true;
        }

        // if (concepto.amount > concepto.total && concepto.amount != 0) {
        //   messageApi.info(
        //     `${concepto?.name} no puede ser superior al valor total`
        //   );
        //   return true;
        // }

        return false;
      })
    ) {
      return;
    }

    const filteredPayment = resultados
      .map((element) => {
        return { paymentMethodId: element._id, amount: element.amount || 0 };
      })
      .filter((concepto) => concepto.amount !== 0);

    if (filteredPayment?.length < 1) {
      messageApi.info("No se permite crear transferencias sin valor");
      return;
    }

    let obj = {
      abFrom: selectedABFrom,
      abTo: selectedABTo,
      transferInput: filteredPayment,
      total: filteredPayment?.reduce((result, item) => {
        return (result += parseFloat(item.amount));
      }, 0),
      abHistoryId: selectedCierre?._id,
    };

    newTransferResolver({ variables: obj })
      .then((res) => {
        if (res?.data?.newTransferResolver?.status === 200) {
          messageApi.success("Transferencia creada correctamente");

          // Hacer cierre Caja;
          handleCierreCaja(filteredPayment);

          // setShowNewTransfer({ open: false, type: null, item: null });
        } else {
          messageApi.error("Ocurrió un error al crear la transferencia");
        }
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Ocurrió un error al crear la transferencia");
      });
    // }
  };

  const handleValues = (value, id) => {
    if (id) {
      let parsedValue = parseFloat(value?.toString()?.replace(",", ".")) || 0;

      setResultados((prevState) => {
        return prevState.map((concepto) => {
          if (concepto._id === id) {
            return { ...concepto, amount: parsedValue };
          } else {
            return concepto;
          }
        });
      });
    }
  };

  const handleCierreCaja = (dataTransferencia) => {
    let transferInput = null;
    if (conceptos?.length > 0) {
      transferInput = conceptos.map((item) => {
        return {
          paymentMethodId: item?._id,
          amount: item?.total || 0,
        };
      });
    }

    if (incluyeTransferencia) {
      // Restar transferencia del total de conceptos.
      if (dataTransferencia && Array.isArray(dataTransferencia)) {
        transferInput = transferInput.map((item) => {
          const valorConceptoTransferencia = dataTransferencia.find(
            (element) => element.paymentMethodId === item.paymentMethodId
          )?.amount;

          let total = item.amount - (valorConceptoTransferencia || 0);

          return {
            paymentMethodId: item.paymentMethodId,
            amount: total || 0,
          };
        });
      }
    }

    const obj = {
      aBHistoryInput: {
        dateFrom: Date.now(),
        dateTo: Date.now(),
        accountBoxId: selectedCierre?.accountBoxId,
      },
      transferInput: transferInput,
      abHistoryId: selectedCierre?._id,
    };

    newABCloseResolver({
      variables: obj,
    })
      .then((res) => {
        messageApi.success("Caja cerrada correctamente");
        setShowNewTransfer({ open: false, type: null, item: null });
        setSelectedCierre(null);
      })
      .catch((error) => {
        console.log("error", error);
        messageApi.error("Ocurrió un error al cerrar la caja");
      });
  };

  useEffect(() => {
    setResultados(
      conceptos?.map((element) => {
        return { ...element, amount: 0 };
      })
    );
  }, [conceptos]);

  const handleGuardar = () => {
    if (incluyeTransferencia) {
      handleGuardarTransferencia();
    } else {
      handleCierreCaja();
    }
  };

  useEffect(() => {
    const { type, item } = showNewTransfer;

    if (type === "edit" && item) {
      setSelectedABFrom(item.abFrom?._id || null);
      setSelectedABTo(item.abTo?._id || null);
      setResultados(item.transferInput || []);
    }
  }, [showNewTransfer]);

  return (
    <div className="modal-transferencia-wrapper">
      <span className="horizontal gap16" style={{ marginTop: 8 }}>
        <span className="columna-select">
          <p style={{ fontWeight: 500 }}>Caja origen</p>
          <Select
            style={{ width: 250 }}
            options={abOptions || []}
            value={selectedABFrom}
            disabled={true}
            // onChange={(v) => setSelectedABFrom(v)}
            placeholder="Seleccionar caja origen"
          />
        </span>
        <span className="columna-select">
          <p style={{ fontWeight: 500 }}>Caja destino</p>
          <Select
            style={{ width: 250 }}
            options={
              abOptions?.filter((element) => {
                return element.value !== selectedABFrom;
              }) || []
            }
            value={selectedABTo}
            disabled={true}
            // onChange={(v) => setSelectedABTo(v)}
            placeholder="Seleccionar caja destino"
          />
        </span>
      </span>
      <span>
        <Checkbox
          checked={incluyeTransferencia}
          // onChange={}
          onClick={(v) => setIncluyeTransferencia(v.target.checked)}
        >
          Incluir transferencia
        </Checkbox>
      </span>
      {incluyeTransferencia && (
        <div className="concepts-grid">
          <span
            className="transferencia-conceptos-fila"
            style={{ marginTop: 16, fontWeight: 500 }}
          >
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              Método de pago
            </p>
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              Disponible
            </p>
            <p className="transferencia-columna" style={{ padding: "0px 8px" }}>
              A transferir
            </p>
          </span>
          {resultados?.map((concepto) => {
            return (
              <span
                className="transferencia-conceptos-fila"
                key={concepto?._id}
              >
                <p className="transferencia-columna">{concepto?.name}</p>
                <p
                  className="transferencia-columna  number-ff"
                  style={{ textAlign: "right" }}
                >
                  {thousandFormatter(concepto?.total)}
                </p>
                <span className="transferencia-columna">
                  <Input
                    type="number"
                    value={concepto?.amount}
                    onChange={(v) => handleValues(v.target.value, concepto._id)}
                  />
                </span>
              </span>
            );
          })}
        </div>
      )}
      <TotalTransferencia resultados={resultados} />
      <div
        className="horizontal gap24"
        style={{
          marginLeft: "auto",
          width: "fit-content",
          marginTop: 16,
        }}
      >
        <Button
          className="btn-guardar"
          onClick={handleGuardar}
          loading={loadingNew || loadingCierre}
        >
          Guardar
        </Button>
        <Button
          className="btn-cancelar"
          onClick={() =>
            setShowNewTransfer({ open: false, type: null, item: null })
          }
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
};
