import { useMemo } from "react";
import { thousandFormatter } from "../../../utils/thousandFormatter";

export const TotalTransferencia = ({ resultados }) => {
  const totalTransfer = useMemo(() => {
    if (!resultados || !Array.isArray(resultados)) return 0;

    return resultados?.reduce((result, item) => {
      console.log({ item });
      return (result += parseFloat(item.amount));
    }, 0);
  }, [resultados]);

  if (!resultados || !Array.isArray(resultados)) {
    return <></>;
  }

  return (
    <span
      className="horizontal"
      style={{
        fontWeight: 600,
        marginLeft: "auto",
        width: "fit-content",
        padding: "0px 8px",
      }}
    >
      <p>TOTAL</p>
      <p>{thousandFormatter(totalTransfer)}</p>
    </span>
  );
};
