import { Button, Drawer, Input, Table, Tooltip } from "antd";
import { useContext, useState } from "react";
import { FiEye } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { GlobalContext } from "../../../context/GlobalContext";
import { ModalCajas } from "../../configuracion/opciones/cierreCajas/ModalCajas";
import { SECCION_PERMISOS } from "../../../../assets/permisos-template";
import { isAvailable } from "../../../../utils/isAvailable";
import { NewCaja } from "./NewCaja";
import { useNavigate } from "react-router-dom";

const columnasCajas = (setCajaData, user) => {
  return [
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar caja"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            className="btn-guardar"
            onClick={confirm}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Borrar
          </Button>
        </div>
      ),
      filterIcon: () => (
        <AiOutlineSearch
          style={{
            color: "var(--background-color)",
            fontSize: "16px",
          }}
        />
      ),
      onFilter: (value, record) => {
        return record?.name?.toLowerCase()?.includes(value.toLowerCase());
      },
    },
    { title: "Descripción", key: "description", dataIndex: "description" },
    {
      title: "Acciones",
      key: "",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (element) => {
        if (element?.isTesoreria) {
          return <></>;
        }

        return (
          isAvailable(
            "settings",
            SECCION_PERMISOS.editar,
            user?.rol?.permissions
          ) && (
            <Tooltip title="Gestionar">
              <span
                className="horizontal"
                style={{
                  width: "fit-content",
                  cursor: "pointer",
                  marginInline: "auto",
                }}
              >
                <FiEye
                  size={18}
                  onClick={() => setCajaData({ open: true, item: element })}
                />
              </span>
            </Tooltip>
          )
        );
      },
    },
  ];
};

export const Cajas = () => {
  const { config, user, loadingGlobal } = useContext(GlobalContext);

  // const [busqueda, setBusqueda] = useState("");

  const navigate = useNavigate();

  const [cajaData, setCajaData] = useState({
    open: false,
    item: null,
    list: [],
  });

  const [drawerData, setDrawerData] = useState({
    open: false,
    type: "",
    item: null,
  });

  const handleDrawer = () => {
    switch (drawerData?.type) {
      case "new":
        return (
          <NewCaja drawerData={drawerData} setDrawerData={setDrawerData} />
        );
      default:
        break;
    }
  };

  const paginado = (config) => {
    let array = [];

    if (config?.accountingBoxes) {
      array = [...config.accountingBoxes];

      // if (busqueda) {
      //   array = array.filter((element) => {
      //     if (element.name?.toLowerCase().includes(busqueda.toLowerCase())) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   });
      // }
    }

    return array;
  };

  return (
    <div className="modulo-wrapper">
      <div className="modulo-header">
        <p className="modulo-header-titulo">Cajas</p>
        {isAvailable(
          "settings",
          SECCION_PERMISOS.crear,
          user?.rol?.permissions
        ) && (
          <Button
            onClick={() =>
              setDrawerData({ type: "new", item: null, open: true })
            }
            style={{ marginLeft: "auto", marginRight: 16 }}
          >
            Nueva caja
          </Button>
        )}
        <Button onClick={() => navigate("/finanzas")}>Volver</Button>
      </div>

      <div className="modulo-content">
        {cajaData?.item?._id && (
          <ModalCajas cajaData={cajaData} setCajaData={setCajaData} />
        )}
        <Table
          className="table-wrapper"
          columns={columnasCajas(setCajaData, user)}
          dataSource={paginado(config)}
          loading={loadingGlobal?.config}
          rowKey={"_id"}
          size="small"
          pagination={{
            pageSize: 10,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} cajas`,
            showSizeChanger: false,
          }}
        />
      </div>

      <Drawer open={drawerData?.open} width={420} closable={false}>
        {handleDrawer()}
      </Drawer>
    </div>
  );
};
