import { StyleSheet, View, Text } from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 24,
  },
  accountBox: {
    fontSize: 13,
    fontStyle: "semibold",
  },
  billTo: {
    paddingBottom: 8,
    fontSize: 12,
    // fontFamily: "Helvetica",
  },
});

export const DetalleCaja = ({ dataCierre }) => {
  const getDate = (timestamp) => {
    if (timestamp) {
      return dayjs(timestamp, "x").format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
  };

  if (dataCierre?.context === "caja") {
    return (
      <View style={styles.headerContainer}>
        <Text style={styles.billTo}>{`CAJA - ${dataCierre?.name}`}</Text>
      </View>
    );
  }

  if (dataCierre?.abFrom || dataCierre?.abTo) {
    return (
      <View style={styles.headerContainer}>
        <Text
          style={styles.billTo}
        >{`Origen: ${dataCierre?.abFrom?.name}`}</Text>
        <Text
          style={styles.billTo}
        >{`Destino: ${dataCierre?.abTo?.name}`}</Text>
        <Text style={styles.billTo}>{`Fecha: ${getDate(
          dataCierre?.createdAt
        )}`}</Text>
      </View>
    );
  }

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.billTo}>{`CAJA - ${dataCierre?.name}`}</Text>
      <Text style={styles.billTo}>{`Apertura: ${getDate(
        dataCierre?.dateFrom
      )}`}</Text>
      <Text style={styles.billTo}>{`Cierre: ${getDate(
        dataCierre?.dateTo
      )}`}</Text>
    </View>
  );
};
